<template>
  <div>
    <ManageFaculty />
  </div>
</template>
<script>
import ManageFaculty from "@/components/Faculty/ManageFaculty";
export default {
  components: {
    ManageFaculty,
  },
  created() {},
};
</script>
